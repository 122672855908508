import React from 'react';
import './alert.css'; // For custom styling

const Alert = () => {
  return (
    <div className="alert">
      <p>
        Mohammed Furquan Saleem has acquired and reserved a new domain. The portfolio hosted on this domain will remain active until December 27, 2024. To access the new website, kindly visit <a href="https://mohammedfurquansaleem.in" target="_blank" rel="noopener noreferrer">https://mohammedfurquansaleem.in</a>
      </p>
    </div>
  );
};

export default Alert;
